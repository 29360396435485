





















































































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiCommunityAdd,
    apiCommunityDetail,
    apiCommunityEdit,
    apiStorehouseList
} from '@/api/delivery'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddCategory extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    hasPid = 0
    storehouseList = []
    lock = false
    form = {
        name: '',
        status: 0,
        warehouse_id: '',
        remark: ''
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入小区名称',
                trigger: ['blur', 'change']
            }
        ],
        warehouse_id: [
            {
                required: true,
                message: '请选择所属仓库',
                trigger: ['blur', 'change']
            }
        ]
    }
    // 保存小区
    handleSave() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                if(this.lock) return;
                this.lock = true;
                this.loading = true
                const api = this.id
                    ? apiCommunityEdit(this.form)
                    : apiCommunityAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                    this.lock = false;
                }).catch(() => {
                    this.lock = false;
                    this.loading = false
                })
            } else {
                return false
            }
        })
    }

    getCategoryDetail() {
        this.loading = true
        apiCommunityDetail(this.id).then((res: any) => {
            if (res.pid) {
                this.hasPid = 1
            }
            this.form = res
            this.loading = false
        })
    }
    // 获取仓库列表
    getstorehouseList() {
        apiStorehouseList({ page_size: 10000 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            this.storehouseList = res?.lists
            console.log('this.storehouseList', this.storehouseList)
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getCategoryDetail()
        this.getstorehouseList()
    }
}
